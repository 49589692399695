import React from "react"
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "gatsby-plugin-intl"

import Layout, { Section } from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ intl }: WrappedComponentProps) => (
  <Layout>
    <SEO
      lang={intl.locale}
      title={`404: ${intl.formatMessage({ id: "title" })}`}
    />
    <Section>
      <div style={{ margin: "3rem 4rem" }}>
        <h1>
          <FormattedMessage id="notfound.header" />
        </h1>
        <p>
          <FormattedMessage id="notfound.description" />
        </p>
      </div>
    </Section>
  </Layout>
)

export default injectIntl(NotFoundPage)
